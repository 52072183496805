import { render, staticRenderFns } from "./ManyOptions.vue?vue&type=template&id=808ec66a"
import script from "./ManyOptions.vue?vue&type=script&lang=ts"
export * from "./ManyOptions.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IxSurveyQuestion: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ix/survey/Question.vue').default})


/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VChip,VSelect})
